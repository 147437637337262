// Header.js

import React from "react";

import { useState, useEffect, useRef } from "react";
import './header.css';
import { useServiceContext } from '../contextapi/Servicecontext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AutoComplete from "./Location";
import { Link} from "react-router-dom";
import Cookies from "js-cookie";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import UserLogin from "../Userlogin/UserLogin";
import apiservice from "../../apiServices";

const Header = () => {

    const { language, changeLanguage , translations, selectedlocation, setSelectedlocation, searchbusiness, setSearchbusiness, setNolocation , userImage ,setUserImage } = useServiceContext();

    const languageOptions = [

        { code: 'en', label: 'En' },
        { code: 'hi', label: 'Hi' },
        { code: 'gj', label: 'Gj' },
    ];

    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const inputRef = useRef(null);
    const geolocationWatcherRef = useRef(null);
    const [isAutoCompleteVisible, setIsAutoCompleteVisible] = useState(false);
    const [metaDetails, setMetaDetails] = useState(null);
    const [error, setError] = useState(null);
    const [isLoginModalVisible, setLoginModalVisibility] = useState(false);
    const [islogged , setIslogged] = useState();
    const autoCompleteRef = useRef(null);
    const urls = window.location.pathname;
    const urlParts = urls.split('/');
    const loc_id = urlParts[urlParts.length - 1]?.split('-').pop();
    const [cityName, setCityName] = useState(null);

    const handleLocationSelect = (selectedlocation) => {
        setSelectedlocation((prevLocation) => {
            return selectedlocation;
        });
    };
    let typingTimeout;

    const handleChange = (event) => {
        clearTimeout(typingTimeout);
        typingTimeout = setTimeout(() => {
            setSearchbusiness(event.target.value);
        }, 500);
    }

    const handlesearchselect = () => {
        setSearchbusiness(searchbusiness)
    }

    const fetchMetaDetails = async () => {
        const api = process.env.REACT_APP_API_URL + 'fetchMetaDetailsByListingCode';

            try {
                
                const responseData = await apiservice(api, 'POST', {listingCode : loc_id});

                setMetaDetails(responseData);
            } catch (error) {
                setError(error.message);
            }
    };


    




    useEffect(() => {
        fetchMetaDetails()
    }, []);

    useEffect(() => {

        const delay = 500; // 1 second delay
        const timeoutId = setTimeout(() => {

            let latitude, longitude, place;
            var currentLocation = {};
            if (location.pathname.match(/^\/l\/([A-Za-z]+)$/)) {
                const cityName = location.pathname.match(/^\/l\/([A-Za-z]+)$/)[1].toLowerCase();
                place = JSON.parse(localStorage.getItem('place'));
                if (place === cityName) {
                    const lat = JSON.parse(localStorage.getItem('lat'));
                    const long = JSON.parse(localStorage.getItem('long'));
                    place = JSON.parse(localStorage.getItem('place'));
                    latitude = parseFloat(lat);
                    longitude = parseFloat(long);
                    currentLocation = {
                        name: place,
                        latitude,
                        longitude,
                    };

                    setSelectedlocation(currentLocation);
                }

                else {

                    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(cityName)}&key=AIzaSyBdj8DEfFrGRm7oPahRe85YxApJ_yzoHrU`)
                        .then(response => response.json())
                        .then(data => {
                            if (data.results.length > 0) {
                                setNolocation(false)
                                const location = data.results[0].geometry.location;
                                const latitude = location.lat;
                                const longitude = location.lng;
                                currentLocation = {
                                    name: cityName,
                                    latitude,
                                    longitude,
                                };
                                localStorage.setItem('long', JSON.stringify(currentLocation.longitude))
                                localStorage.setItem('lat', JSON.stringify(currentLocation.latitude))
                                localStorage.setItem('place', JSON.stringify(currentLocation.name))
                                setSelectedlocation(currentLocation);

                            } else {
                                setNolocation(true)
                            }
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                }
            }

            else if (localStorage.getItem('lat') && localStorage.getItem('long') && localStorage.getItem('place')) {
                const lat = JSON.parse(localStorage.getItem('lat'));
                const long = JSON.parse(localStorage.getItem('long'));
                place = JSON.parse(localStorage.getItem('place'));
                latitude = parseFloat(lat);
                longitude = parseFloat(long);
                currentLocation = {
                    name: place,
                    latitude,
                    longitude,
                };
                setSelectedlocation(currentLocation);
            }

            else {

                if (navigator.geolocation && window.google && window.google.maps) {
                    const options = {
                        enableHighAccuracy: true
                    };

                    geolocationWatcherRef.current = navigator.geolocation.getCurrentPosition(
                        (position) => {

                            const { latitude: lat, longitude: long } = position.coords;
                            latitude = parseFloat(lat);
                            longitude = parseFloat(long);
                            const geocoder = new window.google.maps.Geocoder();
                            const latlng = { lat: latitude, lng: longitude };
                            geocoder.geocode({ location: latlng }, (results, status) => {

                                if (status === "OK" && results[0]) {

                                    const addressComponents = results[0].formatted_address;

                                    if (inputRef.current) {
                                        inputRef.current.value = addressComponents;
                                    }
                                    currentLocation = {
                                        name: addressComponents,
                                        latitude,
                                        longitude,
                                    };
                                    localStorage.setItem('long', JSON.stringify(currentLocation.longitude))
                                    localStorage.setItem('lat', JSON.stringify(currentLocation.latitude))
                                    localStorage.setItem('place', JSON.stringify(currentLocation.name))
                                    setSelectedlocation(currentLocation);
                                }
                            });
                        },
                        (error) => {
                            console.error("Error getting device location:", error);
                        },
                        options
                    );
                } else {
                    console.error("Geolocation or Google Maps API is not supported.");
                }
            }
        }, delay);


        return () => {

            clearTimeout(timeoutId);

            if (geolocationWatcherRef.current) {
                navigator.geolocation.clearWatch(geolocationWatcherRef.current);
            }
        };

    }, []);

    function handleLogout() {
        Cookies.remove('bookingToken')
        Cookies.remove('firstname')
        Cookies.remove('lastname')
        Cookies.remove('mobile')
        setTimeout(()=> {
            setIslogged(() => undefined)
        }, 1)
        
    }
    const usertoken = Cookies.get('bookingToken')
    const userName = Cookies.get('firstname')
    const userLastName = Cookies.get('lastname')
    const userProfileImage = localStorage.getItem('userProfileImage')

    setTimeout(()=> {
        setIslogged(() => usertoken)
    }, 1)
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                autoCompleteRef.current &&
                !autoCompleteRef.current.contains(event.target) &&
                !event.target.closest('.pac-container') &&
                !event.target.classList.contains('search-input')
            ) {
                setIsAutoCompleteVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
     

    useEffect(() => {

        const hasEmptyValue = (obj) => !Object.values(obj).every(value => value);

        if (metaDetails && !hasEmptyValue(metaDetails)) {
            // If metaDetails is valid and all fields have values, update the document's title
            document.title = metaDetails.meta_title;
          } else {
            // Optionally, set a fallback title if metaDetails is invalid or incomplete
            document.title = "whizzQ";
          }

    }, [metaDetails]);

    useEffect(() => {
        const url = window.location.href; // Get the current URL
        const cityMatch = url.match(/\/l\/([^/]+)/); // Regex to extract the city between /l/ and the next /
    
        if (cityMatch && cityMatch[1]) {
          const decodedCityName = decodeURIComponent(cityMatch[1]); // Decode URL-encoded city name
          setCityName(decodedCityName); // Update state with the city name
        }
        else{

            setCityName(null)
        }
      }, [urls]); // Empty dependency array ensures this runs only on component mount
    
    
    return (

        <div>
            <Helmet>
                <title>{metaDetails?.meta_title}</title>
                <meta name="description" content={metaDetails?.meta_description} />
                <meta name="keywords" content={metaDetails?.meta_keywords} />
            </Helmet>
     

            <div className="text-light header-top bg-white z-3">
                <div className="container ">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-4 col-6" >
                            <div className="col d-flex align-items-center" >
                                <div className="col-md-5 ">
                                    <Link to='/l/'> 
                                        <img
                                            src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png"
                                            alt="whizzicon"
                                            style={{ maxWidth: '94%' }}
                                            className="d-none d-md-block"
                                        />
                                         <img
                                            src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/whizzq-icon+6.svg"
                                            alt="whizzicon"
                                            width= "54px"
                                            className="d-md-none"
                                         />
                                        
                                         </Link>
                                </div>
                                <div className="col-md-7"  >

                                    {isAutoCompleteVisible ? (<div className="auto-complete" ref={autoCompleteRef}  > <AutoComplete onSelect={handleLocationSelect} isAutoCompleteVisible={isAutoCompleteVisible} setIsAutoCompleteVisible={setIsAutoCompleteVisible} 
                                    />   </div>

                                    )
                                        : (<div className="loc-tab" onClick={() => setIsAutoCompleteVisible(true)}> <i className="fa-solid fa-location-dot my-location"   >
                                        </i>                                     {(selectedlocation != null && selectedlocation != undefined && Object.keys(selectedlocation).length > 1  && cityName?.length == undefined) ? (<span className="selected-location ml-2 ;">{selectedlocation.name}</span>) : (<span className="selected-location ml-2">{cityName} </span>)}
                                        </div>

                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 col-6 d-none d-md-block">
                            <div className="input-group">
                                <input className="form-control search-input" placeholder={translations.search} onChange={handleChange} />
                                <Link to='/l'>   <div className="input-group-append">
                                    <button className="btn search-btn" type="button" onClick={handlesearchselect}
                                    >
                                        <img
                                            src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector.svg"
                                            alt=""
                                            style={{ maxWidth: '90%', height: 'auto', paddingRight: '4px' }}
                                        />
                                    </button>
                                </div></Link>

                            </div>

                        </div>

                        <div className="col-md-2 col-6 d-flex align-items-center justify-content-end">
                            <a className="d-none d-md-block me-2" href="https://play.google.com/store/apps/details?id=com.schedmad.whizzq&hl=en&gl=US&pli=1">
                                <img
                                    src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/playstore+(1).png"
                                    alt=""
                                    style={{ maxWidth: '30px', height: 'auto' }}
                                />
                            </a>

                            <a className="d-none d-md-block me-2" href="https://apps.apple.com/in/app/whizzq-realtime-booking-app/id1527037346">
                                <img
                                    src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/app-store.png"
                                    alt=""
                                    style={{ maxWidth: '30px', height: 'auto' }}
                                />
                            </a>

                            <div className="d-md-none">

                                {isSearchVisible && (
                                    <div className="overlay d-flex bg-white">

                                        <div className="col-10 src-inpt">
                                            <input
                                                type="text"
                                                className="form-control search-input"
                                                placeholder={translations.search + '....'}
                                                onChange={handleChange}


                                            /> </div>

                                        <div className='col-2 srch-icon' style={{ color: 'black' }} onClick={() => setIsSearchVisible(!isSearchVisible)}>
                                            <Link to='/l/'>     <i className="fa-solid fa-magnifying-glass src-icon" onClick={handlesearchselect}  ></i></Link>
                                        </div>
                                    </div>
                                )}

                                <div className="d-md-none" style={{ color: 'black' }} onClick={() => setIsSearchVisible(!isSearchVisible)}>
                                    <i className="fa-solid fa-magnifying-glass sicon"></i>
                                </div>
                            </div>


                            <div className="language-selector dropdown">

                                <a
                                    className="p-1 rounded dropdown-toggle lang-btn"
                                    type="button"
                                    id="languageDropdownButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fa-solid fa-globe me-1"></i>{languageOptions.find((option) => option.code === language)?.label || 'Select Language'}
                                </a>

                                <ul className="dropdown-menu" aria-labelledby="languageDropdownButton" style={{ minWidth: 'auto' }}>
                                    {languageOptions.map((option) => (
                                        <li key={option.code}>
                                            <button
                                                className={`dropdown-item ${language === option.code ? 'active' : ''}`}
                                                type="button"
                                                onClick={() => changeLanguage(option.code)}
                                            >
                                                {option.label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>


                            { islogged != undefined ? (<div className="dropdown user-width">

                                <div className="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src= {( userImage ? (userImage) :(userProfileImage)  )|| 'https://whizzdatalocal.s3.us-west-2.amazonaws.com/profile_pic/user.png' } alt='default_profile_pic' width='33px' style={{borderRadius : '50%', height: '35px', objectFit: 'fill'}} />
                                    <i className="fa-solid fa-caret-down user-caret-down"></i>

                                </div>

                                <ul className="dropdown-menu user-dropdown" aria-labelledby="dropdownMenuButton">

                                    <li><a className="dropdown-item user-names">{userName}{' '}{userLastName} </a></li>
                                        <li><Link className="dropdown-item" to="/l/"><i className="fa-solid fa-house-chimney user-dashboard"></i> Home</Link></li>
                                       <li><Link className="dropdown-item" to="/l/appointments"><i className="fa-solid fa-calendar-check user-dashboard"></i>  My Appointment</Link></li>
                                       <li><Link className="dropdown-item" to="/l/userprofile"> <i class="fa-solid fa-user user-dashboard"></i> User Profile</Link></li>
                                       <li><Link  className="dropdown-item" onClick={handleLogout}to="/l/"> <i className="fa-solid fa-right-from-bracket user-dashboard"></i> Log out</Link>  </li>
                                </ul>

                            </div>) : (<div className="ms-1" onClick={() => setLoginModalVisibility(true)}> <button className="p-1 rounded  lang-btn lang-btns"
                            >Login</button></div>)
                            }
                        </div>

                    </div>
                </div>
            </div>
            {isLoginModalVisible && (
                <UserLogin onClose={() => setLoginModalVisibility(false)} isLoginModalVisible ={isLoginModalVisible} setLoginModalVisibility ={setLoginModalVisibility} />
            )}



        </div>
    );
}

export default Header;  
