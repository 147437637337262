import React, { useEffect, useState } from 'react';
import './Offers.css';
import { useServiceContext } from '../contextapi/Servicecontext';
import apiservice from '../../apiServices';

export default function BusinessOffer() {
  // State to store the fetched offers
  const [offersData, setOffersData] = useState([]);
  const { setCategoryIds, setServiceIds, setActiveTab, setShowoffer, translations, setOffervalidDate } = useServiceContext();
  const [isLoading,setIsLoading] = useState(false)

  const fetchOffers = async () => {
    setIsLoading(true)
    const api = process.env.REACT_APP_API_URL + 'getOfferByListings';
    const businessLocationId = sessionStorage.getItem('businesslocationId');

    try {
      const responseData = await apiservice(api, 'POST', { listingId: businessLocationId });
      setOffersData(responseData); // Update the state with the fetched data
    } catch (error) {
      console.error('Error fetching offers:', error);
    }finally{
      setIsLoading(false)
    }
  };

  const extractIds = (offerId) => {
    const foundOffer = offersData.find(offer => offer.id == offerId);
    if (foundOffer) {
      const scIds = foundOffer.services.map(service => service.SC_ID);
      const sIds = foundOffer.services.map(service => service.s_id);
      setCategoryIds(scIds);
      setServiceIds(sIds);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  const handleAvailButtonClick = (offerId, offer) => {
    extractIds(offerId); // Call the extractIds function with the offerId
    setActiveTab('service');
    setShowoffer(true);
    setOffervalidDate(offer);
    


  };
  // Display all offers without filtering by offerId
  const displayedOffers = offersData;

  return (
    <div>{
      isLoading ? (
        <div>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '56px',
            width: '56px',
            animation: 'rotate 2s linear infinite',
            marginTop: '80px'
        }} className='loaders loader-overlay'>
        </div>
        <div className='loaders-overlay' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '12px',
        }}>
            <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px' alt="Loading..." />
        </div>
    </div>

      ) : (
        <>
      {displayedOffers.length === 0 ? (
        <div className='no-offer'>No Offers Available.</div>
      ) : (
        displayedOffers.map((offer, index) => (
          <div
            key={index}
            // style={{
            //   border: '1px solid #ccc',
            //   padding: '10px',
            //   margin: '10px',
            //   borderRadius: '12px',
            // }}
            className="d-flex justify-content-between align-items-center business-offer"
          >
            {/* <div>
              <div className="offer-title">{offer.offer_name}</div>
              <div className="offer-description mt-2">{offer.offer_detail}</div>
              <div className="offer-code mt-2">{translations.discount_code}: {offer.offer_code}</div>
            </div> */}
            <div>
              <card-component data={JSON.stringify([offer])} mode='offer' onClick={() => handleAvailButtonClick(offer.id, offer)} ></card-component>
              {/* <button type="button" className="btn btn-light" onClick={() => handleAvailButtonClick(offer.id, offer)}> */}
              {/* </button> */}
            </div>
          </div>
        ))
      )}
      </>
    )}
    </div>
  );
}
