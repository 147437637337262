import React, { useState } from 'react';
import { useRazorpay } from "react-razorpay";
import './payment.css'
import { useServiceContext } from '../contextapi/Servicecontext';

const PaymentComponent = () => {
  const { error, isLoading, Razorpay } = useRazorpay();
  
  const razorpaykey = process.env.REACT_APP_RAZORPAY_KEY_ID;
  const [orderData, setOrderData] = useState(null);
  
  const{selectedServices , bookingdata , partialPayment , setRazorpayDetails ,setRazorpayamount ,setTransactionLogId} = useServiceContext();
   
   const businesslocationId = sessionStorage?.getItem('businesslocationId')
   const userId =  localStorage.getItem('userId')


   function calculatePercentageAmount(percentage, value) {
    if (!Number.isFinite(percentage) || !Number.isFinite(value)) {
      throw new TypeError('Both percentage and value must be finite numbers');
    }
    return (percentage / 100) * value;
  }

  let partialAmount = 0;

  if (Number.isFinite(partialPayment) && bookingdata && Number.isFinite(bookingdata.totalCost)) {
    partialAmount = calculatePercentageAmount(partialPayment, bookingdata.totalCost);
  } else {
    console.warn('Invalid partialPayment or bookingdata.totalCost');
    partialAmount = bookingdata.totalCost
  }  

   setRazorpayamount(partialAmount)
  const remainingAmount = bookingdata.totalCost - partialAmount

  const handlePayment = async () => {
    try {
      // Send a request to the server to create or check the order using fetch
      const response = await fetch('https://stagingapi.whizzq.app/api/create_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          listing_id: businesslocationId,
          cust_id: userId,
          amount: partialAmount,
          paid_amount: partialAmount,
          discount_amount: "",
          remaining_amount: remainingAmount,
          total_amount: bookingdata.totalCost
        }),
      });

      const data = await response.json();

      if (data.status) {
        const { razorPayData, message } = data;
        setTransactionLogId(data.transactionLogId)

          const options = {
            key: razorpaykey,
            amount: razorPayData.total_amount * 100, // Convert to paise
            currency: "INR",
            name: "Test Company",
            description: "Test Transaction",
            order_id: razorPayData.razorPayOrderId, // Use the order ID returned by the server
            handler: (paymentResponse) => {
              setRazorpayDetails(paymentResponse)
              // You can send paymentResponse to your server for further verification
            },
            prefill: {
              name: "John Doe",
              email: "john.doe@example.com",
              contact: "9999999999",
            },
            theme: {
              color: "#F37254",
            },
          };
          const razorpayInstance = new Razorpay(options);
          razorpayInstance.open();
        
    }
    } catch (err) {
      console.error("Error creating order:", err);
      // alert("Error creating order, please try again.");
    }
  };

  return (
    <div className='payment-btn'>
      <button onClick={handlePayment}  disabled={!bookingdata?.totalCost}>Pay Now</button>
    </div>
  );
};

export default PaymentComponent;
