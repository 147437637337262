import React, { useEffect } from 'react';
import './billingprice.css';
import { useState } from 'react';
import { useServiceContext } from '../contextapi/Servicecontext';
import PaymentComponent from '../PaymentComponent/PaymentComponent';
import apiservice from '../../apiServices';


export default function Billingprice() {

    const {
        selectedServices,
        removeService,
        setBookingdata,
        translations,
        toggleServiceIcon,
        showOffer,
        setPartialPayment,
        paymentMode, setPaymentMode

    } = useServiceContext();

    const [totalCost, setTotalCost] = useState(0);


    let listingId = null;
    const businessLocationId = sessionStorage?.getItem('businesslocationId');
    
    if (businessLocationId) {
      try {
        listingId = JSON.parse(businessLocationId);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error or set a default value
      }
    }
    
    


    useEffect(() => {

        const calculatedTotal = selectedServices.reduce((acc, service) => {

            let serviceCost = parseFloat(service.cost);
            // Check if showOffer is true and service has discount_amount
            if (showOffer && service.discount_cost > 0) {
                serviceCost = parseFloat(service.discount_cost);
            }
            if (!isNaN(serviceCost)) {
                return acc + serviceCost;
            }
            return acc;
        }, 0);
    
        setTotalCost(calculatedTotal);
    
        setBookingdata((prevData) => ({
            ...prevData,
            totalCost: calculatedTotal
        }));
    }, [selectedServices, setBookingdata, showOffer]);
    
    const handlremovefunction = ( categoryIndex, service ) => {

        toggleServiceIcon( categoryIndex ,service.serviceIndex)
        removeService(service)}
            
    const totalCount = selectedServices.filter(item => item.hasOwnProperty('serviceIndex')).length;

    const calculateDiscount = () => {
        let discountAmount = 0;
        selectedServices.forEach(service => {
            if (showOffer && service.discount_cost > 0) {
                let originalCost = 0;

                if (service.cost_type === "between") {
                    // Assume using the upper limit of the range for discount calculation
                    originalCost = parseFloat(service.cost.split('-')[1]);
                } else {
                    originalCost = parseFloat(service.cost);
                }
                discountAmount += originalCost - parseFloat(service.discount_cost);
            }
        });
        return discountAmount;
    };


    useEffect(() => {
        const fetchPaymentMode = async () => {
          try {
            const api = process.env.REACT_APP_API_URL + 'checkPaymentMode';
            const response = await apiservice(api, 'POST', { listing_id: listingId });
            
            if (response.status) {
              setPaymentMode(true); // Set payment mode
              setPartialPayment(response.partial)
            } else {
              console.error('Payment mode not found');
            }
          } catch (error) {
            console.error('Error fetching payment mode:', error);
          }
        };
    
        if (listingId) {
          fetchPaymentMode();
        }
      }, [listingId]); 

    return (

        <>
                 <div className='scrollable-bill'>
                    { totalCount < 1 && <div className='selectedservices'> Selected services  will be shown here </div>}
                    
                {selectedServices.map((service, index, categoryIndex, serviceIndex) => (

                    <div className='row flex-fill' key={serviceIndex}>
                        <div className='col-md-6 scrollable-content-alignment-1'>
                            <div className='row'>
                                <text className='serviceprice-head'>{service.service_name}</text>
                            </div>
                            
                        </div>
                        <div className='col-md-3 scrollable-content-alignment-2'>

                            {service.service_name && service.cost && (

                                <text className="mt-auto serviceprice-head float-end">
                                    <img
                                        src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(3).svg"
                                        alt="priceicon"
                                    />{' '}
                                    {service.cost}

                                </text>
                            )}

                        </div>
                        
                        { service.service_name && service.cost && (<div className='remove-icon-billing col-md-3  ' style={{height : '24px' , width: '30px' , marginLeft:'30px' , cursor: 'pointer'}}  onClick={() => handlremovefunction(service.categoryIndex ,service)}
                                                    > <img
                                                            src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/-.svg"
                                                            alt=""
                                                        />
                                                    </div>)}
                                                    <div className='col-md-12'>
                                 <text className='serviceprice-sub-head'>{service.category_name}</text>  
                                </div>
                        {service.service_name && service.cost && (<div> <hr ></hr> </div>)}         
                    </div>

                ))}


            </div>

            <hr></hr>
            <div className='d-flex align-items-center'>
                <div className='col-md-4'>
                    <div className='discount'> <text>{translations.discount}</text> </div>
                    <div className='total-price'> <text>{translations.total}</text> </div>
                </div>
                <div className='col-md-8 text-end'>
                    <div className='discount'>  <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(3).svg" alt="" /><text>{calculateDiscount()}</text> </div>
                    <div className='total-price'> <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/Reactjs/icons/Vector+(3).svg" alt="" /> <text>{totalCost}</text>  </div>
                   {paymentMode &&(<div className='d-none d-md-block' > <PaymentComponent/></div>)}
                </div>
            </div>
        </>
    );
}




